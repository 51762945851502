import {Subscription} from "rxjs";

/**
 * Reasons, why a future interaction conversation started by an agent cannot be picked up by the visitor
 * Must be kept in sync with the middleware so it can hold all reasons properly
 */
export enum AbortReasons {
    ALREADY_CONVERSATION_ACTIVE = "ALREADY_CONVERSATION_ACTIVE",
    MOBILE_SCREEN = "MOBILE_SCREEN",
    USER_CLOSED = "USER_CLOSED",
    CLIENT_SIDE_TIMEOUT = "CLIENT_SIDE_TIMEOUT"
}

export type Logger = Record<"info" | "warn" | "error", (...logData: unknown[]) => void>;
