// export section
export {
    /**
     * Check if the current browser is smaller than a mobile device would be (i.e. it _is_ a mobile device or the browser window is very small)
     */
    isMobileScreen
}

/**
 * Check if the current browser is smaller than a mobile device would be (i.e. it _is_ a mobile device or the browser window is very small)
 */
async function isMobileScreen() {
    // keep the max-width: 544px in sync with com.unblu.platform.client.component.core.responsive.ResponsiveUiService.smallBreakPoint config
    // default: 544px
    return Promise.resolve(window.matchMedia("screen and (max-width:544px)").matches);
}

