import unblu, {
    ActiveIndividualUiView,
    Configuration, Conversation,
    ConversationInfo,
    IndividualUiState,
    UnbluApi
} from "@unblu/floating-js-api";
import {isMobileScreen} from "./unblu-integration-util";
import {
    bufferTime,
    catchError, combineLatest, delay,
    distinct,
    EMPTY,
    filter,
    finalize,
    firstValueFrom,
    from,
    fromEventPattern,
    map,
    merge,
    mergeMap,
    Observable,
    of,
    OperatorFunction, ReplaySubject, Subject, Subscription, switchMap,
    tap,
    timeout,
    toArray
} from "rxjs";
import {AbortReasons} from "./future-interaction-commons";
import {
    doHideFutureInteractionUI,
    doShowFutureInteractionUI,
    isFutureInteractionUIShowing
} from "./future-interaction-ui";

// export section
export {
    /**
     * Register an event listener.
     * The listener is called, when the future interaction popup is about to be shown because either a new
     * future interaction outbound conversation has arrived or a navigation to a page has ahppened and a
     * future interaction outbound conversation was shown before and no inbound session is currently going on.
     *
     * @param {() => Promise<void>} listener called when future interaction popup is about to be shown. Unblu will hold
     * back showing the popup until all listeners promises have resolved or rejected.
     */
    registerFutureInteractionPopupOpenListener,

    /**
     * Register an event listener.
     * The listener is called, when the future interaction popup has hidden because either the user has chosen to close
     * it, or chosen to start to join the future interaction outbound chat.
     * It is the responsibility of the listener code to check also with e.g.
     * {@link isSessionActive} whether a session is active or not
     *
     * @param {() => Promise<void>} listener called when future interaction popup has hidden
     */
    registerFutureInteractionPopupCloseListener,

    /**
     * Unregister a previously registered listener.
     *
     * @param {() => void} listener The listener provided to {@link registerFutureInteractionPopupOpenListener} before
     */
    unregisterFutureInteractionPopupOpenListener,

    /**
     * Unregister a previously registered listener.
     *
     * @param {() => void} listener The listener provided to {@link registerFutureInteractionPopupCloseListener} before
     */
    unregisterFutureInteractionPopupCloseListener,

    /**
     * Coordinates the whole future interaction use case with some help of a middleware.
     */
    observeFutureInteraction,

    /**
     * Reset local storage about a potential active future interaction popup to make sure it's not shown anymore
     *
     * @returns {Promise<void>} resolves when reset is complete
     */
    resetFutureInteraction,

    /**
     * Diagnostic method to just open the future interaction UI without questions asked
     */
    showFutureInteractionUI,

    /**
     * Diagnostic method to just close the future interaction UI without questions asked
     */
    hideFutureInteractionUI,

    /**
     * Meant to be used by FIPO to initialize future interaction outbound chat
     *
     * @returns {Promise<void>} resolves when initialization is complete
     */
    initFutureInteractionOutboundChat
}

declare global {
    /**
     * Global variable `unbluIntegrationComponentMockMiddleware` for testing purposes
     */
    interface Window {
        unbluIntegrationComponentMockMiddleware: boolean;
    }
}

const futureInteractionSessionStorageKey = "unblu-future-interaction";
const sessionStorageVerifiedConversationKey = "-verified-conversations";
const sessionStorageActiveConversationKey = "-active-conversation";
const sessionStorageURLWhitelistKey = "-url-whitelist";

let futureInteractionMiddlewareBaseURL: string;
// const futureInteractionMiddlewareBaseURL = "http://localhost:9090/fi";

let fiPopupAboutToShowListener: FIPopupListener[] = [];
let fiPopupAfterHideListener: FIPopupListener[] = [];
const popupAboutToShowReplaySubject: ReplaySubject<void> = new ReplaySubject<void>();
const popupAfterHideReplaySubject: ReplaySubject<void> = new ReplaySubject<void>();
const popupChatStartedSubject: Subject<void> = new Subject<void>();
const abortWithClientTimeoutSubject = new Subject<FIVerifyConversationsResponse | undefined>();

let log: Record<"info" | "warn" | "error", (...logData: unknown[]) => void> = {
  info: (...logData: any[]) => {},
  warn: (...logData: any[]) => {},
  error: (...logData: any[]) => {}
};

declare type FIPopupListener = {
    handler: () => void,
    replaySubscription: Subscription
};

function registerFutureInteractionPopupOpenListener(listener: () => Promise<void>) {
    const replaySubscription = popupAboutToShowReplaySubject.subscribe(() => {
        log.info("event popup about to show emitted");
        listener();
    });
    fiPopupAboutToShowListener.push({
        handler: listener,
        replaySubscription: replaySubscription
    });
}

function unregisterFutureInteractionPopupOpenListener(listener: () => Promise<void>) {
    const matchingEntry = fiPopupAboutToShowListener.find(element => element.handler === listener);
    if (matchingEntry && matchingEntry.replaySubscription) {
        matchingEntry.replaySubscription.unsubscribe();
    }

    fiPopupAboutToShowListener = fiPopupAboutToShowListener.filter(element => element.handler !== listener);
}

function registerFutureInteractionPopupCloseListener(listener: () => Promise<void>) {
    const replaySubscription = popupAfterHideReplaySubject.subscribe(() => {
        log.info("event after popup hide emitted");
        listener();
    });
    fiPopupAfterHideListener.push({
        handler: listener,
        replaySubscription: replaySubscription
    });
}

function unregisterFutureInteractionPopupCloseListener(listener: () => void) {
    const matchingEntry = fiPopupAfterHideListener.find(element => element.handler === listener);
    if (matchingEntry && matchingEntry.replaySubscription) {
        matchingEntry.replaySubscription.unsubscribe();
    }

    fiPopupAfterHideListener = fiPopupAfterHideListener.filter(element => element.handler !== listener);
}

/**
 * Meant to be used by FIPO to initialize future interaction outbound chat
 *
 * @param {Record<"info" | "warn" | "error", (...logData: unknown[]) => void>} logger
 * @param unbluApiKey The Unblu ApiKey to use
 * @param unbluFIBaseUrl The base url of the Future Interaction outbound chat middleware component
 * @param navigationEvents$ An observable emitting the URL after a navigation step that did not refresh the browser page (i.e. SPA)
 * @param getCurrentUrl A function to return the current URL. May be special in an SPA (i.e. Router url)
 * @param unbluServerUrl The Unblu server URL. Typically empty string when used inside PF
 * @param unbluEntryPath The Unblu entry path. Typically /ap/ga/ub at PF
 * @param unbluNamedArea The name of the named area to use, typically ^fipo-chat-conversation^ inside FIPO
 * @returns {Promise<void>} resolves when initialization is complete
 * */
async function initFutureInteractionOutboundChat(logger: Record<"info" | "warn" | "error", (...logData: unknown[]) => void>,
                                                 unbluApiKey: string,
                                                 unbluFIBaseUrl: string,
                                                 navigationEvents$: Observable<string>,
                                                 getCurrentUrl: () => Promise<URL>,
                                                 unbluServerUrl: string | undefined,
                                                 unbluEntryPath: string | undefined,
                                                 unbluNamedArea: string | undefined): Promise<void> {

    const configApi = async () => {
        if (!unblu.api.isConfigurationNeeded()) {
            return;
        }

        let config: Configuration = {
            apiKey: unbluApiKey
        };

        if (unbluEntryPath) {
            config.entryPath = unbluEntryPath;
        }

        if (unbluServerUrl) {
            config.serverUrl = unbluServerUrl;
        }

        if (unbluNamedArea) {
            config.namedArea = unbluNamedArea;
        }

        unblu.api.configure(config);
    };

    const hasActiveConversation = async (): Promise<boolean> => {
        await configApi();
        let _api = await unblu.api.initialize();
        const isActive = (await _api.getActiveConversation() !== null);
        return isActive;
    };

    await observeFutureInteraction(logger, configApi, unbluFIBaseUrl, hasActiveConversation, isMobileScreen, navigationEvents$, getCurrentUrl);
}

/**
 * Reset local storage about a potential active future interaction popup to make sure it's not shown anymore
 *
 * @returns {Promise<void>} resolves when reset is complete
 */
async function resetFutureInteraction() {
    sessionStorage.removeItem(futureInteractionSessionStorageKey + sessionStorageActiveConversationKey);
}

/**
 * Coordinates the whole future interaction use case with some help of a middleware.
 */
async function observeFutureInteraction(
    logger: Record<"info" | "warn" | "error", (...logData: unknown[]) => void>,
    configApi: () => Promise<void>,
    configFIBaseUrl: string,
    isSessionActive: () => Promise<boolean>,
    isMobileScreen: () => Promise<boolean>,
    navigationEvents: Observable<string>,
    getCurrentUrl: () => Promise<URL>
): Promise<void> {
    log = logger;

    futureInteractionMiddlewareBaseURL = configFIBaseUrl;
    await configApi();
    const _api = await unblu.api.initialize();
    let conversationChangeFromEvent$ = fromEventPattern<ConversationInfo[]> (
        (handler) => {
            log.info("conversationChange event: addHandler called");
            return _api.on(UnbluApi.CONVERSATIONS_CHANGE, handler);
        },
        (handler) => {
            log.info("conversationChange event: removeHandler called");
            return _api.off(UnbluApi.CONVERSATIONS_CHANGE, handler);
        }
    );

    log.info("Preparing Future Interaction event handling");

    let verifiedConversationsSet: Set<string> = new Set<string>();
    const storedVerifiedConversations = sessionStorage.getItem(futureInteractionSessionStorageKey + sessionStorageVerifiedConversationKey);
    if (storedVerifiedConversations) {
        try {
            verifiedConversationsSet = new Set(JSON.parse(storedVerifiedConversations));
        } catch (error) {
            // in any case, just delete the sessionStorage entry and create an empty set
            sessionStorage.removeItem(futureInteractionSessionStorageKey + sessionStorageVerifiedConversationKey);
        }
    }

    getStoredActiveConversation().pipe(
        filter(value => value === undefined),
        mergeMap((restoredConversationResponse: FIVerifyConversationsResponse) => {
            if (hasConversationTimedOut(restoredConversationResponse)) {
                log.info(`Aborting future interaction conversation: Too old (max age: ${restoredConversationResponse.maxAgeInSeconds} seconds)`);

                removeStoredActiveConversation();
                return from(fiAbortConversation(restoredConversationResponse.conversationId, AbortReasons.CLIENT_SIDE_TIMEOUT)).pipe(
                    tap(result => log.info(`Abort conversation ${restoredConversationResponse.conversationId} ${result ? "was successful" : "failed"}`)),
                    switchMap(result => EMPTY),
                    catchError(error => {
                        log.error("Aborting conversation failed, but we won't show it either", error);
                        return EMPTY;
                    }),
                    finalize(() => removeStoredActiveConversation())
                );
            }

            return of(restoredConversationResponse);
        })
    );

    log.info("Verified conversation set: ", verifiedConversationsSet);

    const verifiedConversationChange$ = conversationChangeFromEvent$.pipe(
        groupSortUnifyChangedConversations(),
        switchMap(conversations => combineLatest([of(conversations), getStoredActiveConversationOrUndefined()])),
        tap(([conversations, activeConversation]) => {
            if (activeConversation === undefined) {
                return;
            }

            const endedConversations = conversations.filter(conversation => conversation.ended);
            if (endedConversations.find(conversation => conversation.id === activeConversation.conversationId)) {
                log.info("Future interaction has been ended by the agent");
                removeStoredActiveConversation();
                abortWithClientTimeoutSubject.next(undefined);

                if (isFutureInteractionUIShowing()) {
                    doHideFutureInteractionUI(
                        activeConversation.conversationId,
                        fiAbortConversation,
                        popupAfterHideReplaySubject,
                        undefined,
                        log
                    );
                }
            }
        }),
        map(([conversations, activeConversation]) => conversations),

        filterConversations(verifiedConversationsSet),
        extractFutureInteractionConversation(verifiedConversationsSet)
    );

    const getActiveConversationAfterSPANavigation$ = navigationEvents.pipe(
        switchMap(navigationEvent => getStoredActiveConversation())
    );

    abortWithClientTimeoutSubject.pipe(
        switchMap((verifiedConversation) => {
            return of(verifiedConversation).pipe(
                filter((verifiedConversation) : verifiedConversation is FIVerifyConversationsResponse => verifiedConversation !== undefined),
                delay(conversationTimeInSecondsToTimeout(verifiedConversation as FIVerifyConversationsResponse) * 1000),
                tap((verifiedConversation: FIVerifyConversationsResponse) => {
                    log.info("Hiding UI due to timeout");

                    doHideFutureInteractionUI(
                        verifiedConversation.conversationId,
                        (conversationId: string, abortReason: AbortReasons): Promise<boolean> => {
                            removeStoredActiveConversation();

                            return fiAbortConversation(conversationId, abortReason);
                        },
                        popupAfterHideReplaySubject,
                        AbortReasons.CLIENT_SIDE_TIMEOUT,
                        log
                    );
                })
            )
        })
    )
    .subscribe({
        complete: () => log.warn('abortWithClientTimeout subscription completed'),
        error: err => log.error('abortWithClientTimeout subscription error:', err)
    });

    // make sure our timeout handler stops, when our popup UI is hiding
    popupChatStartedSubject.subscribe(value => abortWithClientTimeoutSubject.next(undefined));

    merge(
        getStoredActiveConversation(),
        getActiveConversationAfterSPANavigation$,
        verifiedConversationChange$
    ).pipe(
        // no matter if we show the popup later on, at this place here we know we have a future interaction conversation
        tap(verifiedConversation => sessionStorage.setItem(futureInteractionSessionStorageKey + sessionStorageActiveConversationKey, JSON.stringify(verifiedConversation))),

        // abort the future interaction conversation if:
        // * the individual UI is already open
        //   (this is better than checking for an active session, because in either case you don't want to suddenly cover the UI with future interaction)
        // * a session is active
        // * it is a mobile device
        switchMap(verifiedConversation =>
                combineLatest([
                    of(verifiedConversation),
                    from(isIndividualUIOpen()),
                    from(isIndividualUIShowingOverview()),
                    from(isSessionActive()),
                    from(getActiveConversation()),
                    from(isMobileScreen()),
                    of(conversationTimeInSecondsToTimeout(verifiedConversation))
                ]),
        ),

        mergeMap(([verifiedConversation, individualUIOpen, overviewShowing, sessionActive, activeConversation, mobileScreen, timeoutInSeconds]) => {
            log.info("Checking conditions. VerifiedConversation, individualUIOpen, sessionActive, mobileScreen, timeoutInSeconds:", verifiedConversation, individualUIOpen, sessionActive, mobileScreen, timeoutInSeconds);

            if (individualUIOpen || sessionActive) {
                if (overviewShowing) {
                    log.info("Overview is showing, not aborting future interaction but also not showing the overlay");
                    return EMPTY;
                }

                if (activeConversation !== null && activeConversation.getConversationId() === verifiedConversation.conversationId) {
                    log.info("User is already interacting with the future interaction conversation - do not show the overlay and not abort anything");
                    // stop any timeouts going on
                    abortWithClientTimeoutSubject.next(undefined);
                    return EMPTY;
                }

                return abortFutureInteraction(verifiedConversation, AbortReasons.ALREADY_CONVERSATION_ACTIVE);
            }

            if (mobileScreen) {
                return abortFutureInteraction(verifiedConversation, AbortReasons.MOBILE_SCREEN);
            }

            if (timeoutInSeconds < 10) {
                return abortFutureInteraction(verifiedConversation, AbortReasons.CLIENT_SIDE_TIMEOUT);
            }

            return of(verifiedConversation);
        }),

        // handle the timeout no matter if we show the popup below or not
        // - this will also abort the conversation on agent side
        tap(verifiedConversation => abortWithClientTimeoutSubject.next(verifiedConversation)),

        // do not continue with further processing if we're not on an interesting whitelisted url
        switchMap(verifiedConversation => combineLatest([of(verifiedConversation), getUrlWhitelist(), from(getCurrentUrl())])),
        switchMap(([verifiedConversation, urlWhitelist, currentUrl]) => {
            const urlPathList: string[] = urlWhitelist.filter(entry => entry.urlPath !== undefined).map(entry => entry.urlPath) as string[];
            const urlPathSuffixList = urlWhitelist.filter(entry => entry.urlPathSuffix !== undefined).map(entry => entry.urlPathSuffix) as string[];
            const urlPathPatternList = urlWhitelist.filter(entry => entry.urlPathPattern !== undefined).map(entry => entry.urlPathPattern) as string[];

            const urlPathMatch = urlPathList.find(urlPath => currentUrl.pathname === urlPath);
            const urlPathSuffixMatch = urlPathSuffixList.find(urlPathSuffix => currentUrl.pathname.endsWith(urlPathSuffix));
            const urlPathPatternMatch = urlPathPatternList.find(urlPathPattern => currentUrl.pathname.match(urlPathPattern) !== null);
            log.info(`URL Whitelist match result. urlPathMatch: ${urlPathMatch}, urlPathSuffixMatch: ${urlPathSuffixMatch}, urlPathPatternMatch: ${urlPathPatternMatch}`);

            return combineLatest([of(verifiedConversation), of(!!urlPathMatch || !!urlPathSuffixMatch || !!urlPathPatternMatch)]);
        }),

        tap(([verifiedConversation, whitelisted]) => log.info(`Right before showing UI for verified conversation. URL whitelisted: ${whitelisted}`, verifiedConversation)),
        switchMap(([verifiedConversation, whitelisted]) => {
            if (whitelisted) {
                log.info("User is on whitelisted page - showing popup");

                return doShowFutureInteractionUI(
                    verifiedConversation.conversationId,
                    verifiedConversation.avatarUrl,
                    verifiedConversation.agentName,
                    verifiedConversation.agentFirstMessage,
                    (conversationId: string, abortReason: AbortReasons): Promise<boolean> => {
                        removeStoredActiveConversation();

                        return fiAbortConversation(conversationId, abortReason);
                    },
                    popupAboutToShowReplaySubject,
                    popupAfterHideReplaySubject,
                    popupChatStartedSubject,
                    log
                );
            }

            log.info("User is not on whitelisted page, hiding popup (if shown)");
            return doHideFutureInteractionUI(
                verifiedConversation.conversationId,
                (conversationId: string, abortReason: AbortReasons): Promise<boolean> => {
                    removeStoredActiveConversation();

                    return fiAbortConversation(conversationId, abortReason);
                },
                popupAfterHideReplaySubject,
                undefined,
                log);
        })
    ).subscribe({
        complete: () => log.warn('Subscription completed'),
        error: err => log.error('Subscription error:', err)
    });
}

// PF Future Interactions Popup

function showFutureInteractionUI(conversationId: string, agentAvatarUrl: string, agentName: string, firstMessage: string) {
    return firstValueFrom(
        doShowFutureInteractionUI(
            conversationId,
            agentAvatarUrl === undefined ? "/ap/ga/ub/fi/avatar/fallback" : agentAvatarUrl,
            agentName,
            firstMessage,
            conversationId === undefined ? (conversationId: string, abortReason: AbortReasons) => Promise.resolve(true) : fiAbortConversation,
            popupAboutToShowReplaySubject,
            popupAfterHideReplaySubject,
            popupChatStartedSubject,
            log
        )
    );
}

function hideFutureInteractionUI(conversationId: string, abortReason: AbortReasons) {
    return doHideFutureInteractionUI(
        conversationId,
        conversationId === undefined ? (conversationId: string, abortReason: AbortReasons) => Promise.resolve(true) : fiAbortConversation,
        popupAfterHideReplaySubject,
        abortReason,
        log
    );
}

/**
 * Abort a conversation by calling the middleware to end it. Removes stored active conversation and cancels any ongoing abort timeouts
 *
 * @param {FIVerifyConversationsResponse} verifiedConversation Conversation to abort
 * @param {AbortReasons} reason reason of abort
 * @returns {Observable<never>} will emit, once abort is complete. Will emit `EMPTY` in all cases.
 */
function abortFutureInteraction(verifiedConversation: FIVerifyConversationsResponse, reason: AbortReasons) {
    log.info(`Aborting conversation ${verifiedConversation.conversationId} (${reason})`);

    removeStoredActiveConversation();
    abortWithClientTimeoutSubject.next(undefined);

    return merge(
        from(doHideFutureInteractionUI(
            verifiedConversation.conversationId,
            (conversationId: string, abortReason: AbortReasons) => Promise.resolve(true),
            popupAfterHideReplaySubject,
            undefined,
            log
        )).pipe(
            switchMap(result => EMPTY),
            catchError(error => {
                log.error(`Failed to hide popup - ignoring error`, error);
                return EMPTY;
            })
        ),
        from(fiAbortConversation(verifiedConversation.conversationId, reason)).pipe(
            tap(result => log.info(`Abort conversation ${verifiedConversation.conversationId} (${reason}) ${result ? "was successful" : "failed"}`)),
            switchMap(result => EMPTY),
            catchError(error => {
                log.error(`Aborting conversation (${reason}) failed, but we won't show it either`, error);
                return EMPTY;
            })
        )
    );
}

/**
 * Check if the Unblu individual UI is currently open or not
 */
async function isIndividualUIOpen(): Promise<boolean> {
    log.info("isIndividualUIOpen called");

    // configApi must have been called before!
    let _api = await unblu.api.initialize();
    const individualUIState = await _api.ui.getIndividualUiState();
    const isOpen = individualUIState === IndividualUiState.OPEN || individualUIState === IndividualUiState.POPPED_OUT;
    return isOpen;
}

/**
 * Check if the Unblu individual UI is currently showing the overivew
 */
async function isIndividualUIShowingOverview(): Promise<boolean> {
    log.info("isIndividualUIShowingOverview called");

    // configApi must have been called before!
    let _api = await unblu.api.initialize();
    const activeIndividualUIState = await _api.ui.getActiveIndividualUiView();
    const isOverviewShowing = activeIndividualUIState === ActiveIndividualUiView.OVERVIEW;
    return isOverviewShowing;
}

/**
 * @returns {Promise<Conversation | null>} A promise resolving either to the active conversation or null (if none is active)
 */
async function getActiveConversation(): Promise<Conversation | null> {
    let _api = await unblu.api.initialize();
    return _api.getActiveConversation();
}

/**
 * Structure used by the middleware to return information about a future interaction conversation waiting
 * Must be kept in sync with the middleware
 */
interface FIVerifyConversationsResponse {
    conversationId: string,
    avatarUrl: string,
    agentName: string,
    agentFirstMessage: string,
    responseTimestamp: number,
    maxAgeInSeconds: number
}

enum FIVerificationResultState {
    VERIFIED_IS_FUTURE_INTERACTION,
    VERIFIED_NOT_FUTURE_INTERACTION,
    UNVERIFIED
}

interface FIVerificationResult {
    conversationId: string,
    result: FIVerificationResultState,
    response: FIVerifyConversationsResponse | null
}

interface FIConversationTimeoutInformation {
    responseTimestamp: number,
    maxAgeInSeconds: number
}

function middlewareVerifyConversations$(conversations: ConversationInfo[]): Observable<FIVerificationResult> {
    const toUnverified = (conversationIds: string[]): Observable<FIVerificationResult> => {
        return from(conversationIds).pipe(
            map(conversationId => ({
                conversationId: conversationId,
                result: FIVerificationResultState.UNVERIFIED,
                response: null
            }))
        )
    };

    const toVerified = (conversationIds: string[], verificationResponse: FIVerifyConversationsResponse | null): Observable<FIVerificationResult> => {
        return from(conversationIds).pipe(
            map(conversationId => {
                let result = FIVerificationResultState.VERIFIED_NOT_FUTURE_INTERACTION;
                let response = null;

                if (verificationResponse) {
                    if (conversationId === verificationResponse.conversationId) {
                        result = FIVerificationResultState.VERIFIED_IS_FUTURE_INTERACTION;
                        response = verificationResponse;
                    }
                }
                return ({
                    conversationId: conversationId,
                    result: result,
                    response: response
                });
            })
        )
    };

    const fIRemoteVerifyConversations = (conversations: ConversationInfo[]): Observable<FIVerificationResult> => {
        const conversationIds: string[] = conversations.map(conversationInfo => conversationInfo.id);

        log.info("ConversationIds to verify for future interaction: ", conversationIds);
        return from(fetch(`${futureInteractionMiddlewareBaseURL}/verifyConversations`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(conversationIds)
        })).pipe(
            timeout(60000),
            tap(response => log.info("Verification result", response)),

            mergeMap(response => {
                if (!response.ok) {
                    log.error("Call to verifyConversations backend failed: ", response.status, response.statusText);
                    return toUnverified(conversationIds);
                }

                if (response.headers.has("Content-Length") && response.headers.get("Content-Length") === "0") {
                    log.info("Call to verifyConversations backend returned no future interaction is among conversations provided");
                    return toVerified(conversationIds, null);
                }

                return from(response.json()).pipe(
                    tap(result => log.info("VerifyConversations returned a future interaction", result)),
                    mergeMap(result => toVerified(conversationIds, result)),
                    catchError(err => {
                        log.error("Handling verifyConversation result somehow failed - we treat them as verified never the less", err);
                        return toVerified(conversationIds, null);
                    })
                );
            }),

            catchError(err => {
                if (err.name === "TimeoutError") {
                    log.error("Conversation verification timed out")
                }

                return toUnverified(conversationIds);
            })
        )
    };

    const fiMockVerifyConversation = (conversations: ConversationInfo[]): Observable<FIVerificationResult> => {
        const conversationIds: string[] = conversations.map(conversationInfo => conversationInfo.id);

        log.info("Mock fiVerifyConversation");
        if (conversations.length > 0) {
            return toVerified(conversationIds, {
                conversationId: conversations[0].id,
                avatarUrl: futureInteractionMiddlewareBaseURL + "/avatar/lehmann_silvia",
                agentName: "Silvia Lehmann",
                agentFirstMessage: "Mein Name ist Silvia Lehmann von PostFinance. Die Wahl der richtigen Vorsorgelösung " +
                    "ist eine sehr persönliche Angelegenheit. Darf ich Ihnen allenfalls Fragen beantworten oder Sie in " +
                    "Ihren Entscheidungen unterstützen?",
                responseTimestamp: new Date().getTime(),
                maxAgeInSeconds: 120
            });
        }

        return EMPTY;
    };

    if (window.unbluIntegrationComponentMockMiddleware) {
        return fiMockVerifyConversation(conversations);
    }

    return fIRemoteVerifyConversations(conversations);
}

async function fiAbortConversation(conversationId: string, abortReason: AbortReasons): Promise<boolean> {

    const fiRemoteAbortConversation = async (conversationId: string, abortReason: AbortReasons): Promise<boolean> => {
        return fetch(`${futureInteractionMiddlewareBaseURL}/abortConversation/${conversationId}?reason=${abortReason}`, )
            .then(response => response.ok);
    }

    const fiMockAbortConversation = async (conversationId: string, abortReason: AbortReasons): Promise<boolean> => {
        return Promise.resolve(true);
    };

    if (window.unbluIntegrationComponentMockMiddleware) {
        return fiMockAbortConversation(conversationId, abortReason);
    }

    return fiRemoteAbortConversation(conversationId, abortReason);
}

interface UrlWhitelistEntry {
    application?: string,
    urlPath?: string,
    urlPathSuffix?: string
    urlPathPattern?: string
}

function getUrlWhitelist(): Observable<UrlWhitelistEntry[]> {
    const storedURLWhitelist = sessionStorage.getItem(futureInteractionSessionStorageKey + sessionStorageURLWhitelistKey);
    if (storedURLWhitelist) {
        return of(JSON.parse(storedURLWhitelist) as UrlWhitelistEntry[]);
    }

    return from(fetch(`${futureInteractionMiddlewareBaseURL}/whitelist`)).pipe(
        timeout(60000),
        tap(response => log.info("Whitelist remote retrieval result", response)),

        mergeMap(response => {
            if (!response.ok) {
                log.error("Call to whitelist backend failed: ", response.status, response.statusText);
                throw new Error("Call to whitelist backend failed");
            }

            return from(response.json()).pipe(
                tap(result => log.info("Whitelist returned", result)),
                map(result => result as UrlWhitelistEntry[]),
                tap(urlWhiteList => sessionStorage.setItem(futureInteractionSessionStorageKey + sessionStorageURLWhitelistKey, JSON.stringify(urlWhiteList))),

                catchError(err => {
                    log.error("Handling whitelist result somehow failed", err);
                    throw err;
                })
            );
        }),

        catchError(err => {
            if (err.name === "TimeoutError") {
                log.error("Whitelist retrieval timed out")
            }

            throw err;
        })
    );
}

/**
 * Returns a conversation that was considered a future interaction one and stored in the session storage. Does not verify whether it has expired.
 * Observable emits undefined, in case of no active conversation
 * @returns {Observable<FIVerifyConversationsResponse>}
 */
function getStoredActiveConversationOrUndefined(): Observable<FIVerifyConversationsResponse | undefined> {
    const storedActiveConversation = sessionStorage.getItem(futureInteractionSessionStorageKey + sessionStorageActiveConversationKey);
    if (storedActiveConversation === null) {
        return of(undefined);
    }

    return of(storedActiveConversation).pipe(
        map(conversationRaw => JSON.parse(conversationRaw) as FIVerifyConversationsResponse),
        tap(restoredConversationResponse => {
            if (restoredConversationResponse.conversationId === undefined) {
                // invalid sessionStorage entry - drop
                removeStoredActiveConversation();
            }
        }),
        map(restoredConversationRespose => restoredConversationRespose.conversationId === undefined ? undefined : restoredConversationRespose)
    );
}

/**
 * Returns a conversation that was considered a future interaction one and stored in the session storage. Does not verify whether it has expired.
 * Observable does not emit anything if there is no active conversation stored.
 * @returns {Observable<FIVerifyConversationsResponse>}
 */
function getStoredActiveConversation(): Observable<FIVerifyConversationsResponse> {
    return getStoredActiveConversationOrUndefined()
        .pipe(
            filter(conversationOrUndefined => conversationOrUndefined !== undefined)
        ) as Observable<FIVerifyConversationsResponse>;
}

function removeStoredActiveConversation() {
    sessionStorage.removeItem(futureInteractionSessionStorageKey + sessionStorageActiveConversationKey);
}

/**
 * Handles conversations coming from conversation change JS API event. Since the JS API event emits many
 * conversations in quick succession this function buffers for one second, then sorts and de-duplicates them
 *
 * @returns {OperatorFunction<ConversationInfo[], ConversationInfo[]>} function that returns an Observable emitting a grouped, sorted and unified list of conversations
 */
function groupSortUnifyChangedConversations(): OperatorFunction<ConversationInfo[], ConversationInfo[]> {
    return (source: Observable<ConversationInfo[]>) => source.pipe(
        bufferTime(3000),

        // required because bufferTime will emit every 1000ms, no matter whether new events were emitted or not - and if there were no events, it emits an empty array
        // may change with the resolution of: https://github.com/ReactiveX/rxjs/issues/2601
        filter(conversations => conversations.length > 0),

        tap(conversationsDoubleArray => log.info("Conversation change emitted", conversationsDoubleArray)),

        map(conversationsDoubleArray => conversationsDoubleArray.flat()),

        // sort, to be able to remove duplicates later
        map(conversations => conversations.sort((a, b) => a.id.localeCompare(b.id))),
        mergeMap(conversations => from(conversations)
            // we need a separate pipe to make sure it will complete after handling all conversations
            .pipe(
                distinct(conversation => conversation.id),
                toArray(),
            )),

        // at this point, it's possible that there are no unverified conversations left - leave it like that
        filter(conversations => conversations.length > 0),
    );
}

/**
 * Check if conversations have previously been verified before and filter out if yes
 *
 * @returns {OperatorFunction<ConversationInfo[], ConversationInfo[]>} rxjs operator
 */
function filterConversations(verifiedConversationsSet: Set<string>): OperatorFunction<ConversationInfo[], ConversationInfo[]> {
    return (source: Observable<ConversationInfo[]>) => source.pipe(
        mergeMap(conversations => from(conversations).pipe(
            filter(conversation => !verifiedConversationsSet.has(conversation.id)),
            toArray()
        ))
    );
}

/**
 * Extracts a future interaction conversation from a list of unknown type conversations.
 * Expensive operation. Assumes as much as possible has been done before to make sure it's not called
 * multiple times for the same conversation(s).
 *
 * @param {Set<string>} verifiedConversationsSet set of previously verified conversations that don't need checking again
 * @returns {OperatorFunction<ConversationInfo[], FIVerifyConversationsResponse>} rxjs operator
 */
function extractFutureInteractionConversation(verifiedConversationsSet: Set<string>): OperatorFunction<ConversationInfo[], FIVerifyConversationsResponse> {
    return (source: Observable<ConversationInfo[]>) => source.pipe(
        // at this point, it's possible that there are no unverified conversations left - leave it like that
        filter(conversations => conversations.length > 0),

        // start verification with middleware
        tap(conversations => log.info("Verifying conversations (check if they're future interaction ones)", conversations)),
        mergeMap((conversations: ConversationInfo[]) =>
            middlewareVerifyConversations$(conversations).pipe(
                tap(verifiedConversation => {
                    log.info("Verified future interaction conversation result: ", verifiedConversation)

                    if (verifiedConversation.result === FIVerificationResultState.VERIFIED_NOT_FUTURE_INTERACTION ||
                        verifiedConversation.result === FIVerificationResultState.VERIFIED_IS_FUTURE_INTERACTION) {

                        verifiedConversationsSet.add(verifiedConversation.conversationId);
                    }
                }),
                filter(verifiedConversationResult => verifiedConversationResult.result === FIVerificationResultState.VERIFIED_IS_FUTURE_INTERACTION),
                map(verifiedConversationResult => verifiedConversationResult.response!),
                finalize(() => {
                    log.info("Updating session storage with current verifiedConversationsSet", verifiedConversationsSet);
                    sessionStorage.setItem(futureInteractionSessionStorageKey + sessionStorageVerifiedConversationKey, JSON.stringify(Array.from(verifiedConversationsSet)));
                })
            )
        )
    );
}

/**
 * For a given conversation timeout information, check if this conversation has timed out now.
 *
 * @param {FIConversationTimeoutInformation} conversationTimeoutInfo
 * @returns {boolean} true if conversation has timed out, false otherwise
 */
function hasConversationTimedOut(conversationTimeoutInfo: FIConversationTimeoutInformation): boolean {
    return conversationTimeInSecondsToTimeout(conversationTimeoutInfo) <= 0;
}

/**
 * Returns the time in seconds until the conversation is going to time out
 * @param {FIConversationTimeoutInformation} conversationTimeoutInfo
 * @returns {number} Number of seconds until the conversation is going to time out
 */
function conversationTimeInSecondsToTimeout(conversationTimeoutInfo: FIConversationTimeoutInformation): number {
    return ((conversationTimeoutInfo.responseTimestamp + conversationTimeoutInfo.maxAgeInSeconds * 1000) - new Date().getTime()) / 1000;
}
