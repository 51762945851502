import unblu from "@unblu/floating-js-api";
import {Observable, Subject, tap, timer} from "rxjs";
import {AbortReasons, Logger} from "./future-interaction-commons";

export {
    doShowFutureInteractionUI,
    doHideFutureInteractionUI,
    isFutureInteractionUIShowing
}

async function doHideFutureInteractionUI(
    conversationId: string,
    fiAbortConversation: (conversationId: string, abortReason: AbortReasons) => Promise<boolean>,
    fiPopupAfterHideSubject: Subject<void>,
    abortReason: AbortReasons | undefined,
    log: Logger,
) {
    if (abortReason !== undefined) {
        fiAbortConversation(conversationId, abortReason);
    }

    let container: HTMLElement | null = document.querySelector("#fi-container");
    if (!container) {
        log.warn("No future interaction container found to hide");
        return;
    }

    container.style.opacity = '0';

    // delay removal of container to allow for fade out transition
    setTimeout(function () {
        let container: HTMLElement | null = document.querySelector("#fi-container");
        if (container) {
            container.remove();
        }

        // alert PFCH about fi popup hidden
        fiPopupAfterHideSubject.next();
    }, 300)
}

function doShowFutureInteractionUI(
    conversationId: string,
    agentAvatarUrl: string,
    agentName: string,
    firstMessage: string,
    fiAbortConversation: (conversationId: string, abortReason: AbortReasons) => Promise<boolean>,
    fiPopupAboutToShowSubject: Subject<void>,
    fiPopupAfterHideSubject: Subject<void>,
    fiPopupChatStartedSubject: Subject<void>,
    log: Logger,
): Observable<any> {
    const openUnbluUI = async () => {
        const _api = await unblu.api.initialize();
        await _api.openConversation(conversationId);
        await _api.ui.openIndividualUi();

        fiPopupChatStartedSubject.next();

        setTimeout(function () {
            container.style.opacity = '0'
            container.remove()
        }, 300)
    }

    const hideUIContainer = () => {
        fiAbortConversation(conversationId, AbortReasons.USER_CLOSED);
        container.style.opacity = '0';

        // delay removal of container to allow for fade out transition
        setTimeout(function () {
            container.remove();

            // alert PFCH about fi popup hidden
            fiPopupAfterHideSubject.next();
        }, 300)
    }

    const container = _createOrUpdateFutureInteractionUI(agentAvatarUrl, agentName, firstMessage);

    // alert PFCH about fi popup shown
    log.info("Calling 'popupAboutToShow' listener");
    fiPopupAboutToShowSubject.next();

    // fade in the container after everything is ready
    log.info("Fading in future interaction UI");
    return timer(250).pipe(
        tap(() => {
            // fade in container
            container.style.opacity = '1'

            // add eventlistener to close-button in FI container
            document.getElementById('fi-container-close')
                ?.addEventListener("click", () => {
                    log.info("Visitor closed popup");

                    return doHideFutureInteractionUI(
                        conversationId,
                        fiAbortConversation,
                        fiPopupAfterHideSubject,
                        AbortReasons.USER_CLOSED,
                        log);
                });

            document.getElementById('fi-start-conversation-button')
                ?.addEventListener('click', openUnbluUI);
        })
    );
}

function isFutureInteractionUIShowing(): boolean {
    let container: HTMLElement | null = document.querySelector("#fi-container");
    return !!container && container.style.opacity !== '0';
}

function _createFutureInteractionUI(agentAvatarUrl: string, agentName: string, firstMessage: string): HTMLElement {
    const applyStyles = (element: HTMLElement, styles: Record<string, any>) => {
        // @ts-ignore
        Object.keys(styles).forEach(key => element.style[key] = styles[key]);
    };

    const container = document.createElement('div');
    container.id = "fi-container";
    applyStyles(container, {
        height: '600px',
        width: '360px',
        backgroundColor: '#f7f7f8',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontFamily: "'Frutiger',Helvetica,sans-serif",
        position: 'fixed',
        right: '32px',
        bottom: '104px',
        zIndex: '3147480647',
        opacity: '0',
        transition: 'opacity 0.3s ease-in-out',
        borderRadius: '5px',
        overflow: 'hidden',
        boxShadow: '5px 5px 5px rgba(0,0,0,0.25)',
    });

    // create close button, eventlistener for removing the container and the fade out effect
    let closeButton = document.createElement('div');
    closeButton.id = "fi-container-close";
    applyStyles(closeButton, {
        width: '100%',
        textAlign: 'right',
        height: '45px',
        color: 'white',
        fontSize: '1.8em',
        padding: '8px 15px 8px 0',
        lineHeight: '23px',
        backgroundColor: "#0f545e",
    });
    closeButton.innerHTML = '<span style="cursor:pointer;">⨉</span>';

    container.appendChild(closeButton);

    // create advisor-icon section
    let advisorAvatar = document.createElement('span');
    advisorAvatar.id = "fi-advisor-avatar";
    applyStyles(advisorAvatar, {
        height: '150px',
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'center',

    });

    let advisorAvatarImg = document.createElement('img');
    advisorAvatarImg.id = "fi-advisor-avatar-img";
    advisorAvatarImg.src = agentAvatarUrl;
    applyStyles(advisorAvatarImg, {
        height: '150px',
        borderRadius: '50%',
    });

    advisorAvatar.appendChild(advisorAvatarImg);
    container.appendChild(advisorAvatar);

    // create advisor name section
    let advisorName = document.createElement('span');
    advisorName.id = 'fi-advisor-name';
    applyStyles(advisorName, {
        width: '90%',
        fontSize: '13px',
        marginBottom: '-20px',
        paddingLeft: '10px',
    });
    advisorName.innerText = agentName;

    container.appendChild(advisorName);

    // create advisor-message section
    const advisorMessage = document.createElement('span');
    advisorMessage.id = 'fi-advisor-message';
    applyStyles(advisorMessage, {
        // height: '250px',
        width: '90%',
        fontSize: '16px',
        lineHeight: '1.65',
        backgroundColor: 'white',
        padding: '15px 10px',
        borderRadius: '5px',
        border: '1px solid #d8d8d8',
        height: '230px',
        overflow: 'hidden',
    });
    advisorMessage.innerText = firstMessage;

    container.appendChild(advisorMessage)

    // create cta section
    const ctaContainer = document.createElement('span');
    ctaContainer.id = 'fi-cta-container';
    applyStyles(ctaContainer, {
        height: '80px',
        width: '90%',
    });

    const ctaButton = document.createElement('span');
    ctaButton.id = 'fi-start-conversation-button';
    applyStyles(ctaButton, {
        backgroundColor: '#FFCC00',
        display: 'flex',
        height: '55px',
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        transition: 'all .15s ease-out,opacity .15s',
        cursor: 'pointer',
        fontSize: '16px',
        borderRadius: '10px',
    });

    const ctaText = document.createElement('span');
    applyStyles(ctaText, {
        width: '90%',
        textAlign: 'left',
        paddingLeft: '25px',
    });
    ctaText.innerHTML = 'Chat starten';

    ctaButton.appendChild(ctaText);


    // this is not ideal, the icon is an image of the unblu-send-icon that's part of an icon-font
    // the icon-font is not ready yet when the script is loaded, so we have to use a base64 encoded version of the icon
    // but the background is yellow instead of transparent - changing the button color will not change the image bg-color
    const ctaSendButtonIcon = document.createElement('img');
    ctaSendButtonIcon.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAABhWlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw1AUhU9bpVUqInYQcYhQnSyIijhqFYpQIdQKrTqYvPQPmjQkKS6OgmvBwZ/FqoOLs64OroIg+APi6uKk6CIl3pcUWsT44PI+znvncN99gL9eZqrZMQ6ommWkEnEhk10Vgq8IoQ9dVMMSM/U5UUzCc33dw8f3uxjP8r735+pRciYDfALxLNMNi3iDeHrT0jnvE0dYUVKIz4nHDGqQ+JHrsstvnAsO+3lmxEin5okjxEKhjeU2ZkVDJZ4ijiqqRvn+jMsK5y3OarnKmn3yF4Zz2soy16mGkMAiliBCgIwqSijDQox2jRQTKTqPe/gHHb9ILplcJTByLKACFZLjB/+D37M185MTblI4DnS+2PbHCBDcBRo12/4+tu3GCRB4Bq60lr9SB2Y+Sa+1tOgR0LsNXFy3NHkPuNwBBp50yZAcKUDlz+eB9zP6pizQfwt0r7lza57j9AFI06ySN8DBITBaoOx1j3eH2uf2753m/H4AQ6RylONPCfgAAAAGYktHRAD/AP8A/6C9p5MAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfnCBEJEhqVB0iHAAAAGXRFWHRDb21tZW50AENyZWF0ZWQgd2l0aCBHSU1QV4EOFwAAAgFJREFUOMutlMtrE1EUh7+ZSdJ0YqJpSvpIIqhtBYstXVhapUUpihsVNba4EF+1ahQ3IpSCUPG1FdwI4j8gFMRFV1oQWpC0i6KxqVlYowv7UtPEmGQmmXFRGonGPIp3c+895/Bx7uV3foLu79D5T8uwdnj4dJ5kSuPisRpsFmldMEH3d+jj0zG6Bt4B4NhoYOisC5+3BrNJLAsmAricpmzg60qa6w/CNB2f5snzRTKaXh5sS30FrY1yTuLzgkL/nQ80971hZOxb6TCAo3ur8ha8DyfwDobYdfotL/wrJcL2VRUsnArG2X81SI9vhsmZH4VhLQ0yW10VRZ8yNhWl/UwA72CI2Y+JnJw0fME9vHZx2o3IlSJ1DhM2i4QkCqRUjYz2NzQ4l+DRyCLheYX2HRuwytKqNIp1E4mlmQ0n6TwXyJvf02pl/HHzb9ECxBMaX5YVliIqS9/T2X05ohL6lMyvekngcLc9dwIAeodCjE5ESlO7ACd6HNy+5KFpszkXFvuZ4eVktCTQwc5N3PN5aNtuyT+boxMRUopWELK7xcr9Kx6622yFB/3Zq3+rfGeDzN3LHg512Yu7hqLqef9qm9vMrQE3Jw9UI4olWtDrQIxoPJMN1lUbuXneTf8RJ0aDUJ6fNXoqqXUYUVSdG6fqudZXi2wW1+dnAGp6VbvldPLn+gV1zKbKCJS1jQAAAABJRU5ErkJggg=="

    ctaButton.appendChild(ctaSendButtonIcon);
    ctaContainer.appendChild(ctaButton);
    container.appendChild(ctaContainer);

    document.body.appendChild(container);
    return container;
}

function _createOrUpdateFutureInteractionUI(agentAvatarUrl: string, agentName: string, firstMessage: string): HTMLElement {
    // create container with all css attributes necessary
    let container: HTMLElement | null = document.querySelector("#fi-container");
    if (!container) {
        container = _createFutureInteractionUI(agentAvatarUrl, agentName, firstMessage);
        return container;
    }

    // @ts-ignore
    const advisorAvatarImg: HTMLImageElement = document.querySelector("#fi-advisor-avatar-img");
    advisorAvatarImg.src = agentAvatarUrl;

    // @ts-ignore
    const advisorName: HTMLElement = document.querySelector("#fi-advisor-message");
    advisorName.innerText = agentName;

    // @ts-ignore
    const advisorMessage: HTMLElement = document.querySelector("#fi-advisor-message");
    advisorMessage.innerText = firstMessage;

    return container;
}

